<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>项目进度</el-breadcrumb-item>
                <el-breadcrumb-item>{{ s === 1 ? "列表" : s === 2 ? "添加" : "编辑" }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary" v-show="s === 1 && !lookShow">
            <div class="_add">
                <el-select v-model="status" clearable placeholder="选择状态" @change="onChange">
                    <el-option label="正常" value="1"></el-option>
                    <el-option label="停止" value="2"></el-option>
                    <el-option label="终止" value="3"></el-option>
                    <el-option label="完成" value="4"></el-option>
                </el-select>
                <button @click="s = 2" v-if="$userLevel === 1">添加</button>
            </div>
            <div class="search">
                <vy-search-box
                    v-model="value"
                    :btn-click="search"
                    placeholder="请输入内容"
                    :select-list="[
                        { label: '项目名', value: 'name' },
                        { label: '法人', value: 'legal_person' },
                    ]"
                    :select-value="select"
                    :select-clearable="false"
                    @change="val => (select = val)"
                    select
                ></vy-search-box>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content" v-show="s === 1 && !lookShow">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="name" align="center" label="项目名"> </el-table-column>
                <el-table-column prop="legal_person" align="center" label="法人"> </el-table-column>
                <el-table-column prop="type" align="center" label="类型"> </el-table-column>
                <el-table-column prop="" align="center" label="周期">
                    <template slot-scope="{ row }">
                        <span>{{ row.start_time }}至{{ row.end_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="residue" align="center" label="剩余时间"> </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span
                            :style="{
                                color:
                                    row.status == 1
                                        ? '#7781f1'
                                        : row.status == 2
                                        ? 'rgb(254, 124, 150)'
                                        : row.status == 3
                                        ? 'red'
                                        : row.status == 4
                                        ? '#ccc'
                                        : '',
                            }"
                            >{{
                                row.status == 1
                                    ? "正常"
                                    : row.status == 2
                                    ? "停止"
                                    : row.status == 3
                                    ? "终止"
                                    : row.status == 4
                                    ? "完成"
                                    : ""
                            }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate" v-if="$userLevel === 1">
                            <button @click="set(row)">编辑</button>
                        </div>
                        <div class="_delete" v-if="$userLevel === 1">
                            <button @click="del(row)">删除</button>
                        </div>
                        <div class="_look">
                            <button @click="look(row)">查看</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页器 -->
            <vy-pager
                :count="total"
                :page="page"
                :page-size="pageSize"
                @current-change="currentChange"
                @size-cahnge="sizeCahnge"
            ></vy-pager>
        </div>
        <template v-if="s === 2">
            <add @stop="stop"></add>
        </template>
        <template v-if="s === 3">
            <set @stop="stop" :row="row"></set>
        </template>
        <template v-if="lookShow">
            <look @stop="stop" :row="row"></look>
        </template>
    </div>
</template>

<script>
import add from "./add.vue";
import axios from "axios";
import set from "./set.vue";
import look from "./look.vue";
import VyPager from "@/components/customs/VyPager.vue";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: { add, set, look, VyPager, VySearchBox },
    data() {
        return {
            tableData: [{}],
            form: {},
            // 1列表 2添加 3编辑
            s: 1,
            select: "name",
            value: "",
            status: "",
            page: 1,
            total: 0,
            pageSize: 10,
            // 需要修改的数据
            row: {},
            lookShow: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let obj = {
                status: this.status,
                page: this.page,
                pageSize: this.pageSize,
            };
            obj[this.select] = this.value;
            let {
                data: { data, count: total },
            } = await axios.get("/api/project_pace/show", {
                params: {
                    ...obj,
                },
            });
            this.total = total;
            return data;
        },
        // 编辑
        set(row) {
            this.row = row;
            this.s = 3;
        },
        // 删除
        async del(row) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => true);
            if (!s) return;
            let { data } = await axios.delete("/api/project_pace/del", { params: { id: row.id } });
            if (data.code !== 200) return this.$message.error(data.msg);
            this.$message.success(data.msg);
            this.tableData = await this.getData();
        },
        // 从添加页返回
        async stop() {
            this.tableData = await this.getData();
            this.s = 1;
            this.lookShow = false;
        },
        // 搜索功能
        async search() {
            this.tableData = await this.getData();
        },
        async onChange() {
            this.tableData = await this.getData();
        },
        // 个人用户查看
        async look(row) {
            this.row = row;
            this.lookShow = true;
        },
        async currentChange(page) {
            this.page = page;
            this.tableData = await this.getData();
        },
        async sizeCahnge(pageSize) {
            this.pageSize = pageSize;
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        display: flex;
        align-items: center;
        margin-top: 20px;
        & > div {
            flex: 1;
        }
        .searchBtn {
            &:hover {
                border-color: transparent !important;
            }
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
</style>
