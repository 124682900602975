<template>
    <div class="page">
        <div class="form">
            <div class="item">
                <div class="lable">项目名</div>
                <div class="content">
                    <el-input v-model="form.name" disabled></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">账号</div>
                <div class="content">
                    <el-input v-model="form.account" disabled></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">法人</div>
                <div class="content">
                    <el-input v-model="form.legal_person" disabled></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">类型</div>
                <div class="content">
                    <el-input v-model="form.type" disabled></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">状态</div>
                <div class="content">
                    <el-select v-model="form.status" clearable disabled>
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="停止" value="2"></el-option>
                        <el-option label="终止" value="3"></el-option>
                        <el-option label="完成" value="4"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">周期</div>
                <div class="content">
                    <el-input :value="form.start_time + ' - ' + form.end_time" disabled></el-input>
                </div>
            </div>
            <div class="item">
                <editor :txt="row.details" :getTxt="() => {}" ref="editor"></editor>
            </div>
        </div>
        <div class="schedule">
            <el-timeline>
                <el-timeline-item
                    v-for="el in scheduleList"
                    :class="el.status == '2' ? 'el-item-error' : 'el-item-success'"
                    :key="el.key"
                    :timestamp="el.status == '2' ? '' : el.create_time"
                    :color="el.color"
                    :icon="el.icon"
                    size="large"
                    class="item"
                >
                    {{ el.content }}
                    <template #dot>
                        <div class="dotIcon">
                            <i :class="el.icon" v-if="el.status != '2'"></i>
                            <span v-else></span>
                        </div>
                    </template>
                </el-timeline-item>
            </el-timeline>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="取消" scale="4" @click.native="$emit('stop')"></VyIcon>
        </div>
    </div>
</template>

<script>
import editor from "@/components/customs/editor.vue";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { editor, VyIcon },
    props: {
        row: Object,
    },
    data() {
        return {
            form: {
                name: "",
                legal_person: "",
                account: "",
                time: ["", ""],
                start_time: "",
                end_time: "",
                type: "",
                course: [],
                details: "",
            },
            // 1开发中 2未完成 3已完成
            scheduleList: [],
        };
    },
    created() {
        //#region
        let row = this.row;
        this.form = { ...row, time: [row.start_time, row.end_time] };
        this.scheduleList = row.course
            .map(e => {
                return {
                    ...e,
                    key: Math.random(),
                    icon: e.status == "1" ? "el-icon-time" : e.status == "2" ? "" : "el-icon-success",
                    color: e.status == "2" ? "#ccc" : "#7781f1",
                };
            })
            .reverse();
        //#endregion
    },
    mounted() {
        this.$refs["editor"].editor.disable();
    },
};
</script>

<style lang="less" scoped>
.page {
    display: flex;

    .form {
        background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
        width: 50%;
        box-sizing: border-box;
        max-height: 600px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: #ccc;
            padding-right: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            width: 3px;
            border-radius: 10px;
            background-color: #7781f1;
        }
        .item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            .lable {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                border: 0;
                border-radius: 20px 0 0 20px;
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }
            .content {
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap;

                /deep/.el-input__inner {
                    flex: 1;
                    width: 100%;
                }
                /deep/.el-select {
                    flex: 1;
                }
                /deep/.el-switch {
                    // margin-left: 20px;
                    margin: 0 20px;
                }
                /deep/.el-switch__label.is-active {
                    color: #7781f1;
                }
                /deep/.el-tag {
                    margin-left: 10px;
                }
            }
        }
    }
    .schedule {
        width: 50%;
        box-sizing: border-box;
        height: 100%;
        max-height: 600px;
        min-height: 100px;
        overflow-y: auto;
        padding: 20px;
        position: relative;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: #ccc;
            padding-right: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            width: 3px;
            border-radius: 10px;
            background-color: #7781f1;
        }
        .el-item-success {
            /deep/.el-timeline-item__tail {
                border-color: #7781f1;
            }
        }
        .el-item-error {
            /deep/.el-timeline-item__tail {
                border-color: #ccc;
            }
        }
        .item {
            /deep/.el-timeline-item__content {
                // white-space: pre-line;
                word-break: break-all;
            }
        }
        .dotIcon {
            background-color: #f2edf3;
            width: 14px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            position: relative;
            left: -1px;
            i {
                color: #7781f1;
            }
            span {
                display: inline-block;
                width: 13px;
                height: 13px;
                background-color: #ccc;
                border-radius: 50%;
            }
        }
    }
}
</style>
