var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page_header"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("项目进度")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.s === 1 ? "列表" : _vm.s === 2 ? "添加" : "编辑"))])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.s === 1 && !_vm.lookShow),expression:"s === 1 && !lookShow"}],staticClass:"page_headerSecondary"},[_c('div',{staticClass:"_add"},[_c('el-select',{attrs:{"clearable":"","placeholder":"选择状态"},on:{"change":_vm.onChange},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"label":"正常","value":"1"}}),_c('el-option',{attrs:{"label":"停止","value":"2"}}),_c('el-option',{attrs:{"label":"终止","value":"3"}}),_c('el-option',{attrs:{"label":"完成","value":"4"}})],1),(_vm.$userLevel === 1)?_c('button',{on:{"click":function($event){_vm.s = 2}}},[_vm._v("添加")]):_vm._e()],1),_c('div',{staticClass:"search"},[_c('vy-search-box',{attrs:{"btn-click":_vm.search,"placeholder":"请输入内容","select-list":[
                    { label: '项目名', value: 'name' },
                    { label: '法人', value: 'legal_person' },
                ],"select-value":_vm.select,"select-clearable":false,"select":""},on:{"change":val => (_vm.select = val)},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.s === 1 && !_vm.lookShow),expression:"s === 1 && !lookShow"}],staticClass:"page_content"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"项目名"}}),_c('el-table-column',{attrs:{"prop":"legal_person","align":"center","label":"法人"}}),_c('el-table-column',{attrs:{"prop":"type","align":"center","label":"类型"}}),_c('el-table-column',{attrs:{"prop":"","align":"center","label":"周期"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row.start_time)+"至"+_vm._s(row.end_time))])]}}])}),_c('el-table-column',{attrs:{"prop":"residue","align":"center","label":"剩余时间"}}),_c('el-table-column',{attrs:{"prop":"status","align":"center","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',{style:({
                            color:
                                row.status == 1
                                    ? '#7781f1'
                                    : row.status == 2
                                    ? 'rgb(254, 124, 150)'
                                    : row.status == 3
                                    ? 'red'
                                    : row.status == 4
                                    ? '#ccc'
                                    : '',
                        })},[_vm._v(_vm._s(row.status == 1 ? "正常" : row.status == 2 ? "停止" : row.status == 3 ? "终止" : row.status == 4 ? "完成" : ""))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.$userLevel === 1)?_c('div',{staticClass:"_operate"},[_c('button',{on:{"click":function($event){return _vm.set(row)}}},[_vm._v("编辑")])]):_vm._e(),(_vm.$userLevel === 1)?_c('div',{staticClass:"_delete"},[_c('button',{on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])]):_vm._e(),_c('div',{staticClass:"_look"},[_c('button',{on:{"click":function($event){return _vm.look(row)}}},[_vm._v("查看")])])]}}])})],1),_c('vy-pager',{attrs:{"count":_vm.total,"page":_vm.page,"page-size":_vm.pageSize},on:{"current-change":_vm.currentChange,"size-cahnge":_vm.sizeCahnge}})],1),(_vm.s === 2)?[_c('add',{on:{"stop":_vm.stop}})]:_vm._e(),(_vm.s === 3)?[_c('set',{attrs:{"row":_vm.row},on:{"stop":_vm.stop}})]:_vm._e(),(_vm.lookShow)?[_c('look',{attrs:{"row":_vm.row},on:{"stop":_vm.stop}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }