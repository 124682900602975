<template>
    <div class="page">
        <div class="form">
            <div class="item">
                <div class="lable">项目名</div>
                <div class="content">
                    <el-input v-model="form.name"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">账号</div>
                <div class="content">
                    <el-select v-model="form.account" clearable placeholder="请选择">
                        <el-option v-for="el in accountList" :key="el" :label="el" :value="el"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">法人</div>
                <div class="content">
                    <el-select v-model="form.legal_person" clearable placeholder="请选择">
                        <el-option v-for="el in legal_personList" :key="el" :label="el" :value="el"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">类型</div>
                <div class="content">
                    <el-select v-model="form.type" clearable placeholder="请选择">
                        <el-option v-for="el in typeList" :key="el.id" :label="el.category_title" :value="el.category_title">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">状态</div>
                <div class="content">
                    <el-select v-model="form.status" clearable placeholder="请选择状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="停止" value="2"></el-option>
                        <el-option label="终止" value="3"></el-option>
                        <el-option label="完成" value="4"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">周期</div>
                <div class="content">
                    <el-date-picker
                        ref="picker"
                        v-model="form.time"
                        :unlink-panels="true"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="item">
                <editor
                    :getTxt="
                        txt => {
                            form.details = txt;
                        }
                    "
                ></editor>
            </div>
        </div>
        <div class="schedule">
            <div class="add">
                <el-button type="primary" icon="el-icon-circle-plus-outline" circle @click="topAdd"></el-button>
            </div>
            <ul>
                <li v-for="(el, i) in scheduleList" :key="el.key">
                    <div class="icon-top" v-show="i !== 0">
                        <i class="el-icon-top"></i>
                    </div>
                    <div
                        class="kuang"
                        :style="{
                            border: `1px solid ${
                                el.status == 1 ? '#1aad19' : el.status == 2 ? '#ccc' : el.status == 3 ? '#7781f1' : ''
                            }`,
                        }"
                    >
                        <div class="iptDiv">
                            <input
                                class="ipts"
                                type="text"
                                v-model="scheduleList[i].content"
                                @keydown.enter="iptSet(el)"
                                @blur="iptSet(el)"
                                :disabled="!el.iptShow"
                            />
                        </div>
                        <div
                            class="is"
                            :style="{
                                color: `${
                                    el.status == 1 ? '#1aad19' : el.status == 2 ? '#ccc' : el.status == 3 ? '#7781f1' : ''
                                }`,
                            }"
                        >
                            <el-tooltip effect="dark" content="开发中" placement="top">
                                <i class="el-icon-loading" @click="el.status = 1"></i>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="完成" placement="top">
                                <i class="el-icon-success" @click="el.status = 3"></i>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="未完成" placement="top">
                                <i class="el-icon-error" @click="el.status = 2"></i>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="编辑" placement="top">
                                <i class="el-icon-setting" @click="set(i)"></i>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="添加(前)" placement="top">
                                <i class="el-icon-top" @click="add('after', i)"></i>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="添加(后)" placement="top">
                                <i class="el-icon-bottom" @click="add('before', i)"></i>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="删除" placement="top">
                                <i class="el-icon-circle-close" @click="del(el.key)"></i>
                            </el-tooltip>
                        </div>
                        <div class="createTime">{{ el.create_time }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="取消" scale="4" @click.native="$emit('stop')"></VyIcon>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import Cookies from "js-cookie";
import editor from "@/components/customs/editor.vue";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { editor, VyIcon },
    data() {
        return {
            form: {
                name: "",
                legal_person: "",
                account: "",
                time: ["", ""],
                start_time: "",
                end_time: "",
                type: "",
                course: [],
                details: "",
            },
            scheduleList: [
                {
                    content: "new",
                    key: Math.random(),
                    status: 1,
                    iptShow: false,
                    create_time: this.timeSet(new Date(), 24),
                },
            ],
            legal_personList: [],
            accountList: [],
            typeList: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            // 获取所有法人
            axios.get("/api/server/legal_person").then(res => {
                this.legal_personList = res.data.map(e => e.legal_person);
            });
            // 获取所有账号
            axios.get("/api/project_pace/account").then(res => {
                this.accountList = res.data;
            });
            // 获取所有分类并取出二级分类
            axios.post("/api/Project/category").then(res => {
                let list = res.data.map(e => e.children).flat();
                this.typeList = list;
            });
        },
        // 提交
        async yes() {
            let obj = qs.stringify({
                ...this.form,
                start_time: this.timeSet(this.form.time[0]),
                end_time: this.timeSet(this.form.time[1]),
                course: this.scheduleList.map(e => {
                    return { content: e.content, status: e.status, create_time: e.create_time };
                }),
            });
            let { data } = await axios.post("/api/project_pace/add", obj);
            if (data.code !== 200) return this.$message.error(data.msg);
            this.$message.success(data.msg);
            this.$emit("stop");
        },
        // 头部添加一个项目流程
        topAdd() {
            this.scheduleList.unshift({
                content: "new",
                key: Math.random(),
                status: 2,
                iptShow: true,
                create_time: this.timeSet(new Date(), 24),
            });
            this.$nextTick(() => {
                // 聚焦新增的项
                document.querySelectorAll(".ipts")[0].focus();
            });
        },
        // 指定位置添加一个项目流程
        add(type, i) {
            if (i === 0) {
                this.topAdd();
                return;
            }
            let index = type === "after" ? i : i + 1;
            this.scheduleList.splice(index, 0, {
                content: "new",
                key: Math.random(),
                status: 2,
                iptShow: true,
                create_time: this.timeSet(new Date(), 24),
            });
            this.$nextTick(() => {
                // 聚焦新增的项
                document.querySelectorAll(".ipts")[index].focus();
            });
        },
        // 编辑
        set(i) {
            this.scheduleList[i].iptShow = !this.scheduleList[i].iptShow;
            this.$nextTick(() => {
                document.querySelectorAll(".ipts")[i].focus();
            });
        },
        // 删除
        del(key) {
            if (this.scheduleList.length <= 1) return this.$message.error("最后一项请勿删除");
            this.scheduleList = this.scheduleList.filter(e => e.key !== key);
        },
        // 输入框失焦或回车
        iptSet(el) {
            el.iptShow = false;
            el.create_time = this.timeSet(new Date(), 24);
        },
        // 处理时间
        timeSet(dateObj, num) {
            if (typeof dateObj === "string") return "";
            if (num === 24) {
                let year = dateObj.getFullYear();
                let month = dateObj.getMonth() + 1;
                let day = dateObj.getDate();
                let hours = dateObj.getHours();
                let minutes = dateObj.getMinutes();
                let seconds = dateObj.getSeconds();
                month = month < 10 ? "0" + month : month;
                day = day < 10 ? "0" + day : day;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            }
            return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    display: flex;

    .form {
        background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
        width: 50%;
        box-sizing: border-box;
        max-height: 600px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: #ccc;
            padding-right: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            width: 3px;
            border-radius: 10px;
            background-color: #7781f1;
        }
        .item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            .lable {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                border: 0;
                border-radius: 20px 0 0 20px;
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }
            .content {
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap;

                /deep/.el-input__inner {
                    flex: 1;
                    width: 100%;
                }
                /deep/.el-select {
                    flex: 1;
                }
                /deep/.el-switch {
                    // margin-left: 20px;
                    margin: 0 20px;
                }
                /deep/.el-switch__label.is-active {
                    color: #7781f1;
                }
                /deep/.el-tag {
                    margin-left: 10px;
                }
            }
        }
    }
    .schedule {
        width: 50%;
        box-sizing: border-box;
        height: 600px;
        overflow-y: auto;

        padding: 20px;
        position: relative;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: #ccc;
            padding-right: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            width: 3px;
            border-radius: 10px;
            background-color: #7781f1;
        }
        .add {
            position: absolute;
            right: 0px;
            top: 20px;
        }
        & > ul {
            width: 90%;
            li {
                .icon-top {
                    text-align: center;
                    font-size: 36px;
                    color: #7781f1;
                }
                .kuang {
                    border-radius: 20px;
                    height: 40px;
                    position: relative;
                    .txt {
                        padding-right: 74px;
                        word-wrap: break-word;
                        line-height: 40px;
                    }
                    .iptDiv {
                        overflow: hidden;
                        height: 100%;
                        border-radius: 20px;
                        line-height: 40px;

                        .ipts {
                            height: 40px;
                            border: none;
                            font-size: 16px;
                            padding-left: 20px;
                            padding-right: 106px;
                            width: 100%;
                            box-sizing: border-box;
                        }
                    }

                    .is {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        line-height: 40px;
                    }
                    .createTime {
                        position: absolute;
                        right: 0px;
                        top: 100%;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
